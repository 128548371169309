import { useState, useEffect } from "react";

import { modConstants } from "Constants";
import { useOnlineStore } from "Stores";

// @ts-ignore  
const getRequiredModifications = modifications => {
    return Object.keys(modifications).reduce((acc, curr) => {
        // @ts-ignore  
        acc[`${curr}IsRequired`] = modifications[curr].length > 0;
        return acc;
    }, {});
};

const getModifications = (modifications: ModificationsToUse[]): Modification[] => {
    return Object.keys(modifications)
        .filter(key => key != "__typename")
        .map(key => ({
            name: key,
            //@ts-ignore
            options: modifications[key]
        })) as Modification[];
};

type ModificationKeys = "flavours" | "sizes";

type ModificationOption = {
    name: string;
    price: number;
    addonPrice: number;
};

type Modification = {
    name: ModificationKeys;
    options: ModificationOption[];
};

type ModificationState = {
    isRequired: boolean;
    modification: ModificationOption | null;
};

type ModificationsToUse = {
    flavours?: ModificationOption[];
    sizes?: ModificationOption[];
};

type UseModifications = {
    onSelectModification: (key: ModificationKeys, value: ModificationOption | null) => void;
    modificationsArray: Modification[];
    selectedModifications: { flavours: ModificationState["modification"]; sizes: ModificationState["modification"] };
    allAreSelected: boolean;
    somethingIsSelected: boolean;
};

export const useModifications = (
    modificationsToUse: ModificationsToUse[],
    initModifications: any
): UseModifications => {
    const { sizesIsRequired, flavoursIsRequired } = getRequiredModifications(modificationsToUse) as {
        sizesIsRequired: boolean;
        flavoursIsRequired: boolean;
    };
    const { restaurantProductModalState } = useOnlineStore();
    const [flavours, setFlavours] = useState<ModificationState>({
        isRequired: flavoursIsRequired,
        modification: null
    });
    const [sizes, setSizes] = useState<ModificationState>({
        isRequired: sizesIsRequired,
        modification: null
    });
    const [allAreSelected, setAllAreSelected] = useState(false);
    const [somethingIsSelected, setSomethingIsSelected] = useState(false);

    const modificationsArray = getModifications(modificationsToUse).reverse();

    useEffect(() => {
        const { sizesIsRequired, flavoursIsRequired } = getRequiredModifications(modificationsToUse) as {
            sizesIsRequired: boolean;
            flavoursIsRequired: boolean;
        };
        setSizes(currSize => ({
            modification: initModifications ? initModifications.sizes : currSize.modification,
            isRequired: sizesIsRequired
        }));
        setFlavours(currFlavour => ({
            modification: initModifications ? initModifications.flavours : currFlavour.modification,
            isRequired: flavoursIsRequired
        }));
    }, [restaurantProductModalState]);

    useEffect(() => {
        if (flavours.modification || sizes.modification) {
            const allModificationSelected =
                (flavours.isRequired ? !!flavours.modification : true) &&
                (sizes.isRequired ? !!sizes.modification : true);

            if (allModificationSelected) {
                setAllAreSelected(true);
            }
        } else {
            setAllAreSelected(false);
        }
    }, [flavours, sizes]);

    const onSelectModification = (key: ModificationKeys, value: ModificationOption | null) => {
        if (key === modConstants.MOD_SIZES) {
            setSizes(currSize => ({ ...currSize, modification: value }));
            setSomethingIsSelected(true);
        } else {
            setFlavours(currFlavour => ({ ...currFlavour, modification: value }));
            setSomethingIsSelected(true);
        }
    };

    return {
        onSelectModification,
        modificationsArray,
        selectedModifications: { flavours: flavours.modification, sizes: sizes.modification },
        allAreSelected,
        somethingIsSelected
    };
};
